
<div *ngIf="visivel" class="cookie-consent" [ngStyle]="{ 'background-color': configuracaoCookie.corFundo}" >
  <div class="cookie-message">
    <quill-editor [(ngModel)]="configuracaoCookie.texto" disabled="true" placeholder="" class="text">
      <div quill-editor-toolbar style="display:none"></div>
    </quill-editor>
  </div>
  <button class="button-1" [ngStyle]="{ 'background-color': configuracaoCookie.corBotaoPoliticaPrivacidade, 'color': configuracaoCookie.fonteBotaoPoliticaPrivacidade}" (click)="openDocument()">Política de Privacidade</button>
  
  <button class="buttons-2-3" [ngStyle]="{ 'background-color': configuracaoCookie.corBotaoAceitar, 'color': configuracaoCookie.fonteBotaoAceitar}" (click)="accept()">Aceitar</button>
  <button class="buttons-2-3" [ngStyle]="{ 'background-color': configuracaoCookie.corBotaoRecusar, 'color': configuracaoCookie.fonteBotaoRecusar}" (click)="deny()">Recusar</button>
  
</div>
