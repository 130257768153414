import { MotivoReprovacaoDTO } from './documentacao/motivoReprovacaoDTO';
import { TipoReprovacaoDTO } from './documentacao/tipoReprovacaoDTO';
import { CursoDTO } from './curso/cursoDTO';
import { NoticiaDTO } from './site/noticiaDTO';


export class ArquivoDTO{

    
    id!: number;
    tipo!: string;
    dataGravacao!: Date;
    path!:any;
    safePath!:any;
    nome!:string;
    noticia!:NoticiaDTO;
    curso!:CursoDTO;
    legenda!:any;
    ordemExibicao!:number;
    linkTarget!:string;
    descricao!:string;
    status!:string;
    motivoStatus!:string;
    motivosReprovacao!: MotivoReprovacaoDTO[];
}