export class NavegacaoDTO{

    id!: number;
    url!: string;
    duracao!: number;
    sessao!: string;
    data!: Date;
    latitude!: any;
    longitude!: any;
    cidade!: string;
    pais!: string;
    estado!: string;
    bairro!: string;
    
    browser!: string;
    versaoBrowser!: string;
    equipamento!: string;
    tipoEquipamento!: string;
    so!: string;
    versaoSO!: string;
    isMobile!: boolean;
    isTablet!: boolean;
    isDesktopDevice!: boolean;
}