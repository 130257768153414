import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { switchMap } from 'rxjs/operators';
import { API_CONFIG } from 'src/app/config/api.config';

@Injectable({
  providedIn: 'root'
})
export class GeolocalizacaoService {

  constructor( private http: HttpClient ) { }



  getGeolocalizacao(): Observable<any> {
    
    return this.getIpGeolocation().pipe(
      switchMap(resp => {
        return this.getLocationByCoords(resp.geoplugin_latitude, resp.geoplugin_longitude);
      })
    );
  }
  

  getIpGeolocation(): Observable<any> {

    return this.http.get<any>( API_CONFIG.apiURL + '/geoplugin/getCoordenadas');
    
  }

  getLocationByCoords(lat: any, lon: any): Observable<any>{
    return this.http.get<any>('https://nominatim.openstreetmap.org/reverse?format=json&lat='+lat+'&lon='+lon);
  }
}
