import { PERFIL } from './../config/perfil.config';
import { PermissaoAcessoService } from './../services/permissao-acesso/permissao-acesso.service';
import { FunctionsService } from './../services/common/functions.service';
import { StorageService } from './../services/storage/storage.service';
import { Injectable } from '@angular/core';
import { Router, CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot } from '@angular/router';

@Injectable()
export class AuthGuard implements CanActivate {

    constructor( private router: Router
               , private storageService: StorageService
               , private functionsService: FunctionsService 
               , private permissaoAcessoService: PermissaoAcessoService ) { }

    async canActivate( route: ActivatedRouteSnapshot
                     , state: RouterStateSnapshot ) {
                

        const token = this.storageService.getToken();
        
        if(token){
            
            const usuarioLogado = this.storageService.getUsuarioLogado();
            const strValidadeToken = this.storageService.getValidadeToken();
            const dtValidadeToken = new Date(Date.parse(strValidadeToken));
            
            const now = new Date();

            //token está válido
            if(dtValidadeToken.getTime()>now.getTime()){

                const urlDestino = window.location.protocol + '//' + window.location.host + state.url;
                
                //return this.permissaoAcessoService.canAccess(usuarioLogado.id, urlDestino);
                const permitido = await this.permissaoAcessoService.canAccess(urlDestino).toPromise();
                
                if(permitido || usuarioLogado.perfil===PERFIL.MASTER){
                    return true;
                }else{
                    this.functionsService.showAlert( 'Desculpe!' 
                                                   , 'Você não possui acesso a esta funcionalidade.<hr/>Você pode solicitar acesso ao administrador do sistema informando a seguinte URL <hr/><b>' + urlDestino + '</b>'
                                                   , [{ 
                                                        text: 'Ok',
                                                        handler: () => {
                                                            this.functionsService.navigate('modulo-gc/gc-funcoes');
                                                        }
                                                    }]);
                }
                
            }else{
                this.functionsService.showAlert('Atenção!','Sua sessão expirou.<br>Por favor, autentique-se novamente.',['Ok']);
                this.router.navigate(['modulo-gc/gc-login'], { queryParams: { returnUrl: state.url }});
                return false;
            }

            
            
            
        }else{
            
            // not logged in so redirect to login page with the return url
            this.router.navigate(['modulo-gc/gc-login'], { queryParams: { returnUrl: state.url }});
            return false;

        }

        
        
    }


    
    
}