import { Injectable } from '@angular/core';
import { CookieService } from 'ngx-cookie-service';
import { StorageService } from '../storage/storage.service';
import { UsuarioService } from '../usuario/usuario.service';

@Injectable({
  providedIn: 'root'
})
export class CookieManagerService {

  constructor( private cookieService: CookieService
             , private storageService: StorageService ) {

    

  }

  setCookie(key: string, value: string, days?: number) {

    // Convert 'days' to milliseconds. There are 86,400,000 milliseconds in a day.
    const expiryDate = days ? new Date(Date.now() + days * 86400000) : undefined;
    this.cookieService.set(key, value, { expires: expiryDate });

    //store the date of cookie registration
    this.setCookieDate(key, new Date());

    
  }

  getCookie(key: string): string {
    return this.cookieService.get(key);
  }

  setAcceptCookies(value: boolean) {
    if (value == true) {
      // Define o cookie com uma data de expiração futura (ex: 1 ano)
      const expiryDays = 365; // Número de dias até expirar
      const date = new Date();
      date.setTime(date.getTime() + (expiryDays * 24 * 60 * 60 * 1000)); // Define a data de expiração
      const expires = "expires=" + date.toUTCString();
      document.cookie = `accept-cookies=true; ${expires}; path=/`; // Armazena o cookie com validade de 1 ano
    } else {
      // Se não aceitar os cookies, armazena a informação na sessionStorage (será perdido após fechamento do navegador)
      sessionStorage.setItem('cookies-refused', 'true');
    }
  }
  

  getAcceptCookies(): boolean {
    return this.cookieService.get('accept-cookies') == 'true' ? true : false;
  }

  getCookiesAcceptanceDate(): Date {
    const date = this.getCookieCreationDate('accept-cookies');

    if(date){
      return new Date(date);
    }

    return null;

  }


  private setCookieDate(key: string, date: Date) {
    this.cookieService.set(key+'_date', date.toISOString());
  }

  private getCookieCreationDate(key: string): Date {
      const value = this.cookieService.get(key+'_date');
      const date = value ? value : null;

      return date ? new Date(date) : null;
  }

  setLastVisitedPage(url: string, timestamp: number) {

    if(url=='/'){
      url = '/home'
    }
    try{
      //this.setCookie('last_visited_url', url);
      //this.setCookie('last_visited_time', timestamp.toString());

      this.storageService.setLastVisitedPage(url);
      this.storageService.setTimeLastVisitedPage(timestamp);
      
      
    }catch(err){
      console.log(err)
    }
  }

  // Retrieve the last visited page URL and visit time from cookies
  getLastVisitedPage(): any {
    /*return {
      url: this.getCookie('last_visited_url'),
      time: parseInt(this.getCookie('last_visited_time'), 10)
    };*/

    return {
      url: this.storageService.getLastVisitedPage(),
      time: this.storageService.getTimeLastVisitedPage(),
      latitude: this.storageService.getGeolocalizacaoVisitante().coords.latitude,
      longitude: this.storageService.getGeolocalizacaoVisitante().coords.longitude,
    };

  }

  setSessionId(sessionID: string){
    try{
      this.setCookie('session_id', sessionID);
      this.storageService.setSessionID(sessionID);
    }catch(err){
      console.log(err)
    }
  }

  getSessionId(): string{
    let sessionId = this.storageService.getSessionID();
    if (!sessionId) {
      sessionId = this.generateSessionId();
      this.setSessionId(sessionId);
    }
    return sessionId;
  }


  clearAllCookies() {
    const cookies = document.cookie.split(';');
    for (let cookie of cookies) {
        const eqPos = cookie.indexOf('=');
        const name = eqPos > -1 ? cookie.substr(0, eqPos) : cookie;
        document.cookie = name + '=;expires=Thu, 01 Jan 1970 00:00:00 GMT;path=/';
    }
  }


  private generateSessionId(): string {
    return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
  }
 
  
  getLocation(): GeolocationPosition {
    return this.storageService.getGeolocalizacaoVisitante();
  }
}
