import { EstiloCSSDTO } from "./estiloCSSDTO";

export class ConfiguracaoCookieDTO{

    id!: number;
    texto!: string;
    urlPoliticaPrivacidade!: string;
    corFundo!: string;
    
    corBotaoPoliticaPrivacidade!: string;
    fonteBotaoPoliticaPrivacidade!: string;

    corBotaoAceitar!: string;
    fonteBotaoAceitar!: string;

    corBotaoRecusar!: string;
    fonteBotaoRecusar!: string;
}