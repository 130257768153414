import { Injectable } from '@angular/core';
import * as moment from 'moment';

@Injectable({
  providedIn: 'root'
})
export class DateFunctionsService {

  constructor() { }

  

  getFilterEndDate(strEndDate: string) {

    if (!strEndDate) {
      return new Date('2100-01-01 00:00:00');
    }

    // Extrair o ano, mês e dia da string
    const [ano, mes, dia] = strEndDate.split('-').map(Number);
  
    // Criar uma data no fuso horário local sem ajustar para UTC
    const dataLocal = new Date(ano, mes - 1, dia, 23, 59, 59); // Note que o mês é baseado em zero, então subtraímos 1
  
    return moment(dataLocal).toDate();
  }

  getFilterStartDate(strStartDate: string) {

    if (!strStartDate) {
      return new Date('2000-01-01 00:00:00');
    }

    // Extrair o ano, mês e dia da string
    const [ano, mes, dia] = strStartDate.split('-').map(Number);
  
    // Criar uma data no fuso horário local sem ajustar para UTC
    const dataLocal = new Date(ano, mes - 1, dia, 0, 0, 0); // Note que o mês é baseado em zero, então subtraímos 1
  
    return moment(dataLocal).toDate();
  }

  getDateBrPattern(data: Date): string {
    data = new Date(data);

    let dia = data.getDate().toString(),
      diaF = (dia.length == 1) ? '0' + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = (mes.length == 1) ? '0' + mes : mes,
      anoF = data.getFullYear();

    return diaF + "/" + mesF + "/" + anoF;
  }

  getDateHourBrPattern(data: Date): string {
    data = new Date(data);

    let dia = data.getDate().toString(),
      diaF = (dia.length == 1) ? '0' + dia : dia,
      mes = (data.getMonth() + 1).toString(), //+1 pois no getMonth Janeiro começa com zero.
      mesF = (mes.length == 1) ? '0' + mes : mes,
      anoF = data.getFullYear(),
      hora = data.getHours().toString(),
      horaF = (hora.length == 1) ? '0' + hora : hora,
      min = data.getMinutes().toString(),
      minF = (min.length == 1) ? '0' + min : min;

    return diaF + "/" + mesF + "/" + anoF + ' ' + horaF + ':' + minF;
  }

  getHourBrPattern(data: Date): string {
    data = new Date(data);
    let hora = data.getHours().toString(),
      horaF = (hora.length == 1) ? '0' + hora : hora,
      min = data.getMinutes().toString(),
      minF = (min.length == 1) ? '0' + min : min;

    return horaF + ':' + minF;
  }

  addBusinessDays(startDate, numDays) {
    let currentDate = new Date(startDate);

    while (numDays > 0) {
      // Adiciona um dia ao currentDate.
      currentDate.setDate(currentDate.getDate() + 1);

      // Verifica se o dia da semana de currentDate é sábado (6) ou domingo (0).
      if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
        numDays--;
      }
    }

    return currentDate;
  }

  formatDateToYMD(date: Date): string {
    return moment(date).format('YYYY-MM-DD');
  }

  parseDate(dateString: string): Date {
    const [year, month, day] = dateString.split('-').map(Number);
    return new Date(year, month - 1, day); // Month is zero-indexed in JavaScript Date
  }

  getLocalDateString(date: Date): string {
    const ano = date.getFullYear();
    const mes = ('0' + (date.getMonth() + 1)).slice(-2); // Adiciona zero à esquerda se o mês for < 10
    const dia = ('0' + date.getDate()).slice(-2); // Adiciona zero à esquerda se o dia for < 10
    return `${ano}-${mes}-${dia}`;
  }
  
}
