import { HttpErrorResponse, HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest, HttpResponse } from "@angular/common/http";
import { stringify } from "@angular/compiler/src/util";
import { Injectable } from "@angular/core";
import { Observable } from "rxjs/internal/Observable";
import { throwError } from "rxjs/internal/observable/throwError";
import { catchError, map } from "rxjs/operators";

@Injectable()
export class HttpConfigInterceptor implements HttpInterceptor {
    
    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        
        let token = localStorage.getItem('token');
        
        let headers = new HttpHeaders();

        if(request.url.indexOf('/arquivosfisicos/upload')===-1 && //upload file não usa content-type:json
        request.url.indexOf('/arquivosfisicos/copyAndPaste')===-1){ //copy file não usa content-type:json
            
            headers = new HttpHeaders({
                'Authorization': `${token}`,
                'Content-Type': 'application/json'
            });

        }else{

            headers = new HttpHeaders({
                'Authorization': `${token}`,
                //'Content-Type': 'multipart/*'
            });

        }
        
        const cloneReq = request.clone({headers});

        
        return next.handle(cloneReq).pipe(
            map((event: HttpEvent<any>) => {
                if (event instanceof HttpResponse) {
                    
                }

                return event;
            }),
            catchError((error: HttpErrorResponse) => {
                
                let errorObj = error;
                if (errorObj.error) {
                    errorObj = errorObj.error;
                }
                
                return throwError(error);
            }));
    }
}