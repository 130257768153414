import { DIRETORIO } from './../../config/diretorio.config';
import { ENTIDADE } from './../../config/entidade.config';
import { ArquivoDTO } from './../../models/arquivoDTO';
import { ArquivoService } from './../../services/arquivo/arquivo.service';
import { MenuDTO } from '../../models/site/menuDTO';
import { FunctionsService } from './../../services/common/functions.service';
import { MenuService } from './../../services/menu/menu.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-menu',
  templateUrl: './menu.component.html',
  styleUrls: ['./menu.component.scss'],
})
export class MenuComponent implements OnInit {

  showingSubmenu = '';
  showingSubmenu2 = '';

  menuItems = [];
  menus: MenuDTO[] = [];

  logo: ArquivoDTO = new ArquivoDTO();

  constructor( private menuService : MenuService
             , private functionsService: FunctionsService
             , private arquivoService: ArquivoService ) { }

  ngOnInit() {
    this.menuItems = this.menuService.getItems();


    //busca os menus
    this.menuService.findAll().subscribe({
      next:(resp)=>{
        this.menus = resp;
      },
      error:(error)=>{
        console.log(error);
      }
    });

    this.arquivoService.findByPath(ENTIDADE.SITE, 0, DIRETORIO.LOGO).subscribe({
      next:(respLogo)=>{
        if(respLogo.length>0){
          this.logo = respLogo[0];
        }
        
      },
      error:(errorLogo)=>{
        console.log(errorLogo);
      }

    });
  }


  


  /*
  método usado para tablets, disparado através do click no menu, pois em tablet não tem cursor do mouse
  */
  showHideSubmenu(idSubmenu: string){
    
    //se clicou no mesmo menu que já está aberto então apenas oculta ele
    if(this.showingSubmenu==idSubmenu){
      this.showingSubmenu = '';
    }else{
      this.showingSubmenu = idSubmenu;
    }

    let element = document.getElementById(idSubmenu);
    
    if(element){

      if(element.style.display === 'block'){
        element.style.display = 'none';
      }else{
        element.style.display = 'block';
      }
    }
    

  }


  showHideSubmenu2(idSubmenu2: string){
    
    this.showingSubmenu2 = idSubmenu2;

    let element = document.getElementById(idSubmenu2);
    
    if(element){

      if(element.style.display === 'block'){
        element.style.display = 'none';
      }else{
        element.style.display = 'block';
      }
    }
    

  }


  goToPath(path:string){

    if(path){
      this.functionsService.goToPath(path);
      document.getElementById('mainMenu').style.visibility = 'hidden';      
    }
  }


  getFilePublicURL(fullPath: string, fileName: string): string{
    
    if(!fullPath || !fileName){
      return '/assets/images/logo.png'; //API_CONFIG.iisVirtualDirectoryURL + 'empty_photo_270x70.jpg';
    }

    return this.arquivoService.getFilePublicURL(fullPath, fileName);
  }

}
