import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { API_CONFIG } from 'src/app/config/api.config';
import { ConfiguracaoCookieDTO } from 'src/app/models/site/configracaoCookieDTO';

@Injectable({
  providedIn: 'root'
})
export class ConfiguracaoCookieService {

  constructor(private http: HttpClient){ 
  }

  insert(configuracaoCookieDTO: ConfiguracaoCookieDTO) : Observable<any> {
    

    return this.http.post<any>( API_CONFIG.apiURL+'/configuracoescookies'
                              , configuracaoCookieDTO
                              , { observe: 'response' }); //.pipe(timeout(100000))

  }

  update(configuracaoCookieDTO: ConfiguracaoCookieDTO) : Observable<any> {
    console.log(configuracaoCookieDTO)
    return this.http.put<any>( API_CONFIG.apiURL+'/configuracoescookies/' + configuracaoCookieDTO.id
                             , configuracaoCookieDTO
                             , { observe: 'response' });

  }


  findAll() : Observable<ConfiguracaoCookieDTO[]> {

    return this.http.get<ConfiguracaoCookieDTO[]>( API_CONFIG.apiURL+'/configuracoescookies');

  }

  
  find(id: number) : Observable<ConfiguracaoCookieDTO> {

    return this.http.get<ConfiguracaoCookieDTO>( API_CONFIG.apiURL+'/configuracoescookies/' + id);

  }

  delete(id: number) : Observable<void> {

    return this.http.delete<void>( `${API_CONFIG.apiURL}${'/configuracoescookies/'}${id}`);

  }
}
