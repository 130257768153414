import { Observable } from 'rxjs/internal/Observable';
import { API_CONFIG } from './../../config/api.config';
import { FormacaoDTO } from '../../models/curso/formacaoDTO';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class FormacaoService {

  constructor(private http: HttpClient){ 
  }

  insert(formacaoDTO: FormacaoDTO) : Observable<any> {
    

    return this.http.post<any>( API_CONFIG.apiURL+'/formacoes'
                              , formacaoDTO
                              , { observe: 'response' }); //.pipe(timeout(100000))

  }

  update(formacaoDTO: FormacaoDTO) : Observable<any> {
    
    return this.http.put<any>( API_CONFIG.apiURL+'/formacoes/' + formacaoDTO.id
                             , formacaoDTO
                             , { observe: 'response' });

  }


  findAll() : Observable<FormacaoDTO[]> {

    return this.http.get<FormacaoDTO[]>( API_CONFIG.apiURL+'/formacoes');

  }

  find(id: number) : Observable<FormacaoDTO> {

    return this.http.get<FormacaoDTO>( API_CONFIG.apiURL+'/formacoes/' + id);

  }

  delete(id: number) : Observable<void> {

    return this.http.delete<void>( `${API_CONFIG.apiURL}${'/formacoes/'}${id}`);

  }
}
