import { HttpClient } from '@angular/common/http';
import { MenuDTO } from '../../models/site/menuDTO';
import { Observable } from 'rxjs/internal/Observable';
import { FormacaoDTO } from '../../models/curso/formacaoDTO';
import { FormacaoService } from './../formacao/formacao.service';
import { API_CONFIG } from './../../config/api.config';
import { Injectable } from '@angular/core';
import { StorageService } from '../storage/storage.service';

@Injectable({
  providedIn: 'root'
})
export class MenuService {

  

  LABEL_INSTITUICAO = 'A Instituição';
  LABEL_CURSOS = 'Cursos';
  LABEL_FORMAS_INGRESSO = 'Formas de Ingresso';
  LABEL_BIBLIOTECA = 'Biblioteca';
  LABEL_NOTICIAS = 'Notícias';
  LABEL_ATENDIMENTO = 'Atendimento';
  
  LABEL_GERENCIAMENTO_CONTEUDO = 'Gerenciar';
  
  subItensFormacoes: Object[] = [];

  
  constructor( private http: HttpClient
             , private formacaoService: FormacaoService 
             , private storageService: StorageService) { 

    this.formacaoService.findAll().subscribe(
      resp=>{
        
        for(var i=0; i<resp.length; i++){
          
          const subItemFormacao = {
            label: resp[i].nome.toUpperCase(),
            icon: 'chevron-down-outline',
            path: '/listar-cursos?if=' + resp[i].id
          }

          //add o subitem no array
          this.subItensFormacoes.push(subItemFormacao);

          
        }

      },
      error=>{
        console.log(error);
      }
    );

  }


  getItems(): Object[]{

    let menuItems = [
      {
        label: this.LABEL_INSTITUICAO,
        icon: 'chevron-down-outline',
        path: '',
        subitems:[

          {
            label: 'SOBRE',
            icon: 'chevron-down-outline',
            path: 'https://wp.souzamarques.br/a-instituicao/',
          },
          {
            label: 'CENTRO DE MEMÓRIA',
            icon: 'chevron-down-outline',
            path: 'https://wp.souzamarques.br/a-instituicao/centro-de-memoria/',
          },
          {
            label: 'COLÉGIO SOUZA MARQUES',
            icon: 'chevron-down-outline',
            path: 'http://colegiosouzamarques.com/',
          },
          {
            label: 'ENADE',
            icon: 'chevron-down-outline',
            path: 'https://wp.souzamarques.br/a-instituicao/enade-2/',
          },
          {
            label: 'CPA - AVALIAÇÃO INSTITUCIONAL',
            icon: 'chevron-down-outline',
            path: 'https://wp.souzamarques.br/a-instituicao/cpa-avaliacao-institucional/',
          },
          {
            label: 'DAP - DEPARTAMENTO DE APOIO PSICOPEDAGÓGICO',
            icon: 'chevron-down-outline',
            path: 'https://wp.souzamarques.br/dap/',
          },
          {
            label: 'PORTAL DE PERIÓDICOS DA FTESM',
            icon: 'chevron-down-outline',
            path: 'http://revista.souzamarques.br',
          },
          {
            label: 'CEP FTESM',
            icon: 'chevron-down-outline',
            path: "https://souzamarques.br/site-files/cep/CEP_FTESM.pdf",
            newWindow: true,
          },
          {
            label: 'CONVÊNIOS',
            icon: 'chevron-down-outline',
            path: 'https://wp.souzamarques.br/formas-de-ingresso/convenios/',
          },
          
        ]
      },

      {
        label: this.LABEL_CURSOS,
        icon: 'chevron-down-outline',
        path: '',
        subitems:this.subItensFormacoes //add o array de subitens preenchido no construtor da tela
      },
      /*{
        label: this.LABEL_FORMAS_INGRESSO,
        icon: 'chevron-down-outline',
        path: '',
        subitems:[
          {
            label: 'GRADUAÇÃO',
            icon: 'chevron-down-outline',
            path: 'https://vestibular.souzamarques.br',
          },
          {
            label: 'PÓS-GRADUAÇÃO',
            icon: 'chevron-down-outline',
            path: 'https://wp.souzamarques.br/cursos/pos-graduacao/',
          },
          {
            label: 'EXTENSÃO',
            icon: 'chevron-down-outline',
            path: 'https://wp.souzamarques.br/cursos/extensao/',
          },
          {
            label: 'INSCRIÇÃO VESTIBULAR 2022',
            icon: 'chevron-down-outline',
            path: 'https://vestibular.souzamarques.br',
          }
        ]
      },*/
      {
        label: this.LABEL_BIBLIOTECA,
        icon: 'chevron-down-outline',
        path: 'https://wp.souzamarques.br/biblioteca/sobre',
        subitems:[]
      },
      {
        label: this.LABEL_NOTICIAS,
        icon: 'chevron-down-outline',
        path: '/listar-noticias',
        subitems:[]
      },
      {
        label: this.LABEL_ATENDIMENTO,
        icon: 'chevron-down-outline',
        path: 'https://wp.souzamarques.br/contatos/',
        subitems:[]
      }
    ];

    return menuItems;
  }



  
  


  saveAll(menus: MenuDTO[]) : Observable<any> {

    return this.http.post<any>( API_CONFIG.apiURL+'/menus/saveAll'
                              ,  JSON.stringify(menus)
                              , { observe: 'response' }); //.pipe(timeout(100000))

  }


  insert(menuDTO: MenuDTO) : Observable<any> {

    return this.http.post<any>( API_CONFIG.apiURL+'/menus'
                              , menuDTO
                              , { observe: 'response' });

  }

  update(menuDTO: MenuDTO) : Observable<any> {

    
    return this.http.put<any>( API_CONFIG.apiURL+'/menus/' + menuDTO.id
                             , menuDTO
                             , { observe: 'response' });

  }


  findAll() : Observable<MenuDTO[]> {

    return this.http.get<MenuDTO[]>( API_CONFIG.apiURL+'/menus');

  }

  
  
  find(id: number) : Observable<MenuDTO> {

    return this.http.get<MenuDTO>( API_CONFIG.apiURL+'/menus/' + id);

  }

  delete(id: number) : Observable<void> {

    return this.http.delete<void>( `${API_CONFIG.apiURL}${'/menus/'}${id}`);

  }


  deleteAll(menus: MenuDTO[]) : Observable<any> {

    return this.http.post<any>( API_CONFIG.apiURL+'/menus/deleteAll'
                              ,  JSON.stringify(menus)
                              , { observe: 'response' }); //.pipe(timeout(100000))

  }


  changeURL(menu: MenuDTO){

    return;
    
    if(window.location.host.indexOf('localhost')>-1 && menu.urlDestino){
            
      menu.urlDestino = menu.urlDestino.replace('souzamarques.br',window.location.host).replace('https','http');
    
    }else if(window.location.host.indexOf('hmgsite')>-1 && menu.urlDestino){

      menu.urlDestino = menu.urlDestino.replace('souzamarques.br',window.location.host);

    }

  }


  

}
