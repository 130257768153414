<ion-menu side="start" menuId="mainMenu" id="mainMenu" contentId="mainScreen" swipeGesture="false">
  
  

  <ion-content>

    <!--este menu é exibido em telas mobile-->
    <div class="ion-margin-top" *ngFor="let menu of menus">
      
      <!--MENU PRINCIPAL-->
      <ion-item (click)="showHideSubmenu(menu.label); goToPath(menu.urlDestino);" 
                class="item-menu"
                lines="none"
                *ngIf="menu.ativo">
        
        <!--label do menu principal-->
        {{menu.label}}

        <ion-icon *ngIf="menu.submenus.length>0" 
                  [name]="showingSubmenu===menu.label ? 'remove-outline' : 'add-outline'"></ion-icon>
      
      </ion-item>

      <!--SUBMENU-->
      <div *ngIf="showingSubmenu===menu.label && menu.submenus.length>0" 
           class="submenu" 
           id={{menu.label}}>
     
        <span *ngFor="let submenu of menu.submenus">
          <ion-item (click)="showHideSubmenu2(submenu.label); goToPath(submenu.urlDestino)" 
                    lines="none" 
                    routerDirection="root"
                    class="item-menu"
                    *ngIf="submenu.ativo">
            
            <!--label do submenu-->
            {{submenu.label}}

            <ion-icon *ngIf="submenu.submenus.length>0" 
                      [name]="showingSubmenu===menu.label ? 'remove-outline' : 'add-outline'" ></ion-icon>   
          
          </ion-item>

          <!--SUBMENU2-->
          <div *ngIf="showingSubmenu===menu.label && showingSubmenu2===submenu.label && submenu.submenus.length>0" 
               class="submenu" 
               id={{submenu.label}}>
        
            <span *ngFor="let submenu2 of submenu.submenus">
              <!--label do submenu2-->
              <ion-item (click)="goToPath(submenu2.urlDestino)" 
                        lines="none" 
                        routerDirection="root"
                        class="item-menu"
                        *ngIf="submenu2.ativo" >
                {{submenu2.label}}
              </ion-item>
            </span>
          </div>
        </span>
      </div>

    </div>    
    
  </ion-content>
  
</ion-menu>