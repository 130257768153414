import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { API_CONFIG } from 'src/app/config/api.config';
import { NavegacaoDTO } from 'src/app/models/site/navegacaoDTO';
import { CookieManagerService } from './cookie-manager.service';
import { GeolocalizacaoService } from '../geolocalizacao/geolocalizacao.service';
import { StorageService } from '../storage/storage.service';
import { DateFunctionsService } from '../common/date-functions.service';

@Injectable({
  providedIn: 'root'
})
export class AnalyticsService {

  
  constructor( private http: HttpClient, private dateFunctionsService: DateFunctionsService ) {

    

  }

  insertNavigation(navegacao: NavegacaoDTO): Observable<any> {
    
    //robô google?
    if(navegacao.url.toString().toLocaleLowerCase().includes('?gclid=') ||
       navegacao.url.toString().toLocaleLowerCase().includes('&gclid=') ||
       navegacao.url.toString().toLocaleLowerCase().includes('?gad_source=') ||
       navegacao.url.toString().toLocaleLowerCase().includes('&gad_source=') ||
       navegacao.url.toString().toLocaleLowerCase().includes('?fbclid=') ||
       navegacao.url.toString().toLocaleLowerCase().includes('&fbclid=') ||
       navegacao.url.toString().toLocaleLowerCase().includes('?_gl=') ||
       navegacao.url.toString().toLocaleLowerCase().includes('&_gl=')){
        return;
    }

    if(navegacao.url.includes('/login')){
      navegacao.url = '/login';
    }

    if(navegacao.url.includes('/selecao-forma-ingresso')){
      navegacao.url = '/selecao-forma-ingresso';
    }

    if(navegacao.url.includes('/selecao-turno')){
      navegacao.url = '/selecao-forma-ingresso';
    }
    
    if(navegacao.url=='/'){
      navegacao.url = '/home'
    }

    navegacao.url = decodeURIComponent(navegacao.url);

    
    return this.http.post<any>( API_CONFIG.apiURL+'/analytics'
                              , navegacao
                              , { observe: 'response' });

  }


  findByDateBetween(startDate: Date, endDate: Date): Observable<NavegacaoDTO[]> {
    const formattedStartDate = this.dateFunctionsService.formatDateToYMD(startDate);
    const formattedEndDate = this.dateFunctionsService.formatDateToYMD(endDate);
    return this.http.get<NavegacaoDTO[]>(API_CONFIG.apiURL + '/analytics/findByDateBetween?startDate=' + formattedStartDate + '&endDate=' + formattedEndDate);
  }
  
  


  



}
