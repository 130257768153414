<ion-app>
  
  <app-menu></app-menu>

  <ion-router-outlet id="mainScreen" class="main"></ion-router-outlet>

  <app-cookies-consent></app-cookies-consent>


</ion-app>
