import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { interval } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CacheService {

  constructor(public updates: SwUpdate) {

    if (updates.isEnabled) {
      interval(6 * 60 * 60).subscribe(() => updates.checkForUpdate()
                           .then(() => console.log('Buscando novas versões do site...')));
    }
  }

  public checkForUpdates(): void {
    this.updates.versionUpdates.subscribe(event => {
      this.promptUser()
    });
  }

  private promptUser(): void {
    console.log('Nova versão encontrada. Atualizando agora....');
    this.updates.activateUpdate().then(() => document.location.reload()); 
    console.log('Site atualizado');
  }
}
