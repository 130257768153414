import { API_CONFIG } from 'src/app/config/api.config';
import { Observable } from 'rxjs/internal/Observable';
import { LogErroDTO } from './../../models/logErroDTO';
import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class LogErroService {

  constructor( private http: HttpClient ){ 
  }

  insert(logErroDTO: LogErroDTO) : Observable<any> {

    return this.http.post<any>( API_CONFIG.apiURL+'/logserros'
                              , logErroDTO
                              , { observe: 'response' });

  }

  
  findAll() : Observable<LogErroDTO[]> {

    return this.http.get<LogErroDTO[]>( API_CONFIG.apiURL+'/logserros');

  }



  find(id: number) : Observable<LogErroDTO> {

    return this.http.get<LogErroDTO>( API_CONFIG.apiURL+'/logserros/' + id);

  }

}
