import { UsuarioDTO } from "./controle-acesso/usuarioDTO";

export class LogErroDTO{

    id!: number;
    data!: Date;
    pagina!: string;
    operacao!: string;
    erro!: string;
    usuarioLogado!: UsuarioDTO;
    
}